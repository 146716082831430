import React, { useState, useEffect } from 'react';
import './Navbar.css'; 
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import logo from './images/tarakram-logo.png';
import { Navbar, Container, Nav, Offcanvas } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';

const CustomNavbar = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });
  const isMobile1 = useMediaQuery({ query: '(max-width: 500px)' });
  const isMobile2 = useMediaQuery({ query: '(max-width: 353px)' });

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const location = useLocation();

  const navbarStyle = {
    backgroundColor: '#210C6E',
    padding: isMobile ? '1.5em 0.2em' : '1.5em 2.8em',
    display: 'flex',
    justifyContent: isMobile ? 'flex-start' : 'flex-end',
    top: 0,
    width: '100%',
    transition: 'background-color 0.8s ease',
    fontFamily: 'Maven Pro',
  };

  const hamburger = {
    backgroundColor: '#fff',
    position: 'fixed',
    top: isMobile2 ? '3em' : (isMobile1 ? '1.7em' : '1.3em'),
    right: isMobile2 ? '2.5em' : (isMobile1 ? '1em' : '2em'),
    display: scrollPosition > 40 ? 'none' : 'inline',
    fontSize: isMobile2 ? '0.7em' : (isMobile1 ? '1.1em' : '1.3em')
  };

  const handleCloseOffcanvas = () => setShowOffcanvas(false);

  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} expand={expand} className="custom-navbar" fixed='none' style={navbarStyle}>
          <Container fluid>
            <Navbar.Brand href="/">
              <img
                src={logo}
                height="45em"
                alt="Brand Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} style={hamburger} onClick={() => setShowOffcanvas(true)} />
            <Navbar.Offcanvas
              show={showOffcanvas}
              onHide={handleCloseOffcanvas}
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              style={{ backgroundColor: '#210C6E', color: 'white', padding: '0.5em 1em' }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} style={{ fontSize: '2em', fontWeight: '1000', fontFamily: 'Maven Pro' }}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="/" className='navLinkss'>Home</Nav.Link>
                  <Nav.Link as={Link} smooth to="/#about" className='navLinkss' onClick={handleCloseOffcanvas}>About Me</Nav.Link>
                  <Nav.Link as={Link} smooth to="/#projects" className='navLinkss' onClick={handleCloseOffcanvas}>My Projects</Nav.Link>
                  <Nav.Link as={Link} smooth to="/#achievement" className='navLinkss' onClick={handleCloseOffcanvas}>My Achievements</Nav.Link>
                  <Nav.Link href="/contact" className='navLinkss'>Contact Me</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default CustomNavbar;
