import React, {useState} from "react";
import CustomNavbar from "./Navbar";
import './contact.css'
import Footer from "./footer";
import ContactPhoto from './images/ImgMain.png'
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { Modal, Button } from "react-bootstrap";
import { faEnvelope, faPhone, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from "react-responsive";
import { database } from './firebase';
import { ref, push } from "firebase/database";
import emailjs from '@emailjs/browser';

function Contact () {

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const [showDoneReport, setShowDoneshowDoneReport] = useState(false);

    const handleCloseShowDoneReport = () => setShowDoneshowDoneReport(false);
    const handleShowDoneshowDoneReport = () => setShowDoneshowDoneReport(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name && validateEmail(email) && message) {
            const dataToStore = {
                name: name,
                email: email,
                message: message
            };

            const dataRef = ref(database, "message-contact");
            push(dataRef, dataToStore)
            .then(() => {
                console.log('Data has been stored in Firebase.');

                // Send email using EmailJS with the data from Firebase
                emailjs.send('service_apcd55a', 'template_jejzrnm', {
                    to_email: 'tarakram2003@gmail.com',
                    subject: 'Website message',
                    name: dataToStore.name,
                    email: dataToStore.email,
                    message: dataToStore.message,
                }, 'Pjh05O3PPHpKASlMj')
                .then((result) => {
                    console.log('Email sent successfully:', result.text);
                })
                .catch((error) => {
                    console.error('Error sending email:', error.text);
                });

                // Clear the input fields after submission
                setName('');
                setEmail('');
                setMessage('');
                handleShowDoneshowDoneReport()
            })
            .catch((error) => {
                console.error('Error storing data in Firebase:', error);
            });
        } else {
            alert('Please fill in all the required fields properly');
        }
    };

    const isMobile = useMediaQuery({ query: '(max-width: 800px)' });

    return (
        <div style={{fontFamily: 'Maven Pro'}}>
            <CustomNavbar />
            <div style={{minHeight: '80vh', backgroundImage: 'linear-gradient(180deg, rgba(33,12,110,1) 50%, rgba(255,255,255,1) 50%)'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh'}}>
                    <img src={ContactPhoto} alt="Tarakram Maram" className="contact-page-image"/>
                </div>
                <div style={{textAlign: 'center', marginTop: '-1em'}}>
                    <h2 style={{fontFamily: 'Maven Pro', fontSize: '2.4em', fontWeight: '1000'}}>Get in Touch with Me</h2>
                    <div>
                        <a href="https://www.instagram.com/tarakrammaram/" target='_blank'><FontAwesomeIcon icon={faInstagram} className='social-link-2'/></a>
                        <a href="https://in.linkedin.com/in/tarakram-maram-593b431b2" target='_blank'><FontAwesomeIcon icon={faLinkedin} className='social-link-2'/></a>
                        <a href='mailto:tarakram2003@gmail.com'><FontAwesomeIcon icon={faEnvelope} className='social-link-2'/></a>
                        <a href='mailto:tarakram2003@gmail.com'><FontAwesomeIcon icon={faWhatsapp} className='social-link-2'/></a>
                        <a href='mailto:tarakram2003@gmail.com'><FontAwesomeIcon icon={faPhone} className='social-link-2'/></a>
                    </div>
                </div>
            </div>
            <div style={{ padding: '3em' }}>
                <h2 style={{ fontSize: '2.2em', fontWeight: '1000', margin: '0.5em 0 1em' }}>Send a Message</h2>
                <div className='ContactForm'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <div className='contactForm'>
                                    <form id='contact-form' onSubmit={handleSubmit} noValidate>
                                        {/* Row 1 of form */}
                                        <div className='row formRow' style={{ display: `${isMobile ? 'inline' : 'flex'}` }}>
                                            <div className='col-6' style={{ width: `${isMobile ? '100%' : '50%'}`, marginBottom: '1em' }}>
                                                <input
                                                    type='text'
                                                    name='name'
                                                    className='form-control-1 formInput'
                                                    placeholder='Name'
                                                    value={name}
                                                    onChange={handleNameChange}
                                                ></input>
                                            </div>
                                            <div className='col-6' style={{ width: `${isMobile ? '100%' : '50%'}` }}>
                                                <input
                                                    type='email'
                                                    name='email'
                                                    className='form-control-1 formInput'
                                                    placeholder='Email address'
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                ></input>
                                            </div>
                                        </div>
                                        {/* Row 3 of form */}
                                        <div className='row formRow'>
                                            <div className='col'>
                                                <textarea
                                                    rows={4}
                                                    name='message'
                                                    className='form-control-1 formInput'
                                                    placeholder='Message'
                                                    value={message}
                                                    onChange={handleMessageChange}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <button className='contact-page-button' type='submit'>
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{marginTop: '3em'}}>
                <div className="map-div">
                    <div className="map-div-1">
                        <div>
                            <h2 style={{fontSize: '3em', fontWeight: '1000'}}>Reach Me Here</h2>
                        </div>
                    </div>
                    <div className="map">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15555.154365882494!2d77.583758!3d12.921305!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae152b6a1c6d2f%3A0x1055f8ab50c80979!2sVision%20Digital%20India!5e0!3m2!1sen!2sin!4v1715584338752!5m2!1sen!2sin" 
                            width="100%" 
                            height="350em" 
                            allowfullscreen="" 
                            loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal show={showDoneReport} onHide={handleCloseShowDoneReport}>
              <Modal.Body>
                <Modal.Title style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  <FontAwesomeIcon icon={faCheck} style={{padding: '0.5em', fontSize: '2.3em', color: '#210C6E', fontWeight: '1000'}}/>
                </Modal.Title>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '0.3em 1em 0' }}>
                  <p>Thank you for your submission! I will review your submission and get back to you within 24 hours.</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '1em 0'}}>
                  <Button style={{backgroundColor: '#210C6E', border: 'none'}} onClick={handleCloseShowDoneReport}>
                    Close
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
        </div>
    )
}

export default Contact;
