import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyAUv98XIbYHVBQiR-j11nxXmPiowRkUQTY",
    authDomain: "tarakram-website.firebaseapp.com",
    databaseURL: "https://tarakram-website-default-rtdb.firebaseio.com",
    projectId: "tarakram-website",
    storageBucket: "tarakram-website.appspot.com",
    messagingSenderId: "1090950868673",
    appId: "1:1090950868673:web:b5dd86b5d7722c2df32e40",
    measurementId: "G-LNZWZ7BX6N"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { app, database };