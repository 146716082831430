import React, {useState} from "react";
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import logo from './images/Tanay.png'; // Import your logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faCheck } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css';
import Form from 'react-bootstrap/Form';
import {
  faInstagram,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Offcanvas } from 'react-bootstrap';  
import {
    MDBInput
  }
from 'mdb-react-ui-kit';
import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';
import { HashLink as Link } from 'react-router-hash-link';
import { Nav } from 'react-bootstrap';
import { database } from './firebase';
import { ref, push } from "firebase/database";
import emailjs from '@emailjs/browser';

const Footer = () => {

  const currentYear = new Date().getFullYear();
  const [show, setShow] = useState(false);  
  const closeSidebar = () => setShow(false);  
  const showSidebar = () => setShow(true); 

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });

  const variant = {
    hidden: {
      opacity: 0,
      x: -50, 
      y: -50
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        duration: 1.5, 
      },
    },
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleNameChange = (e) => {
      setName(e.target.value);
  };

  const handleEmailChange = (e) => {
      setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
      setMessage(e.target.value);
  };

  const [showDoneReport, setShowDoneshowDoneReport] = useState(false);

  const handleCloseShowDoneReport = () => setShowDoneshowDoneReport(false);
  const handleShowDoneshowDoneReport = () => setShowDoneshowDoneReport(true);

  const handleSubmit = (e) => {
      e.preventDefault();
      if (name && validateEmail(email) && message) {
          const dataToStore = {
              name: name,
              email: email,
              message: message
          };
        
          const dataRef = ref(database, "message-reach-me"); 
          push(dataRef, dataToStore)
          .then(() => {
            console.log('Data has been stored in Firebase.');

            // Send email using EmailJS with the data from Firebase
            emailjs.send('service_apcd55a', 'template_jejzrnm', {
              to_email: 'tarakram2003@gmail.com',
              subject: 'Website message',
              name: dataToStore.name,
              email: dataToStore.email,
              message: dataToStore.message,
            }, 'Pjh05O3PPHpKASlMj')
              .then((result) => {
                console.log('Email sent successfully:', result.text);
              })
              .catch((error) => {
                console.error('Error sending email:', error.text);
              });
          })
          .catch((error) => {
            console.error('Error storing data in Firebase:', error);
          });
          setName('')
          setEmail('')
          setMessage('')
          closeSidebar()
          handleShowDoneshowDoneReport()

      } else {
          alert('Please fill in all the required fields properly');
      }
  };

  const [email1, setEmail1] = useState('')

    const handleEmailChange1 = (e) => {
        setEmail1(e.target.value);
    };

    const handleSubmit1 = (e) => {
        e.preventDefault();
        if (validateEmail(email1)) {
            const dataToStore = {
                name: email1,
                email: email1,
                message: 'Stay in Touch Column'
            };
          
            const dataRef = ref(database, "message-stay-in-touch"); 
            push(dataRef, dataToStore)
            .then(() => {
              console.log('Data has been stored in Firebase.');

              // Send email using EmailJS with the data from Firebase
              emailjs.send('service_apcd55a', 'template_jejzrnm', {
                to_email: 'tarakram2003@gmail.com',
                subject: 'Website message',
                name: dataToStore.name,
                email: dataToStore.email,
                message: dataToStore.message,
              }, 'Pjh05O3PPHpKASlMj')
                .then((result) => {
                  console.log('Email sent successfully:', result.text);
                })
                .catch((error) => {
                  console.error('Error sending email:', error.text);
                });
            })
            .catch((error) => {
              console.error('Error storing data in Firebase:', error);
            });
            setEmail1('')
            handleShowDoneshowDoneReport()

        } else {
            alert('Please fill in all the required fields properly');
        }
    };

  return (
    <footer style={{padding: '3em 3em 1em', backgroundColor: '#210C6E', fontFamily: 'Maven Pro'}}>
      <div style={{padding: '2em 2em 6em'}}>
        {isMobile ? <>
          <Col xs={12} md={3} style={{marginRight: '2.2em'}}>
            <a href="/"><img src={logo} alt="Logo" className='logo-footer-company-1' /></a>
          </Col>
        </> : <></>}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          variants={variant}
        >
          <p className='work-with-me-text'>Ready to catalyse your next breakthrough?</p>
        </motion.div>
        <button onClick={showSidebar} className='work-with-me-button'>Work With Me</button>
      </div>
      <Container>
        <Row>
          <Col xs={12} md={3} style={{marginRight: '2.2em'}}>
            <a href="/"><img src={logo} alt="Logo" className='logo-footer-company' /></a>
          </Col>
          <Col xs={12} md={2} style={{marginRight: '3em', paddingBottom: '1em'}}>
            <h4 style={{color:'#fff', fontWeight: '800', paddingBottom: '0.3em'}}>Website</h4>
            <ul className="list-unstyled">
              <li><Nav.Link as={Link} smooth to="/#about" className="footer-link-content" style={{margin: '0'}}>About Me</Nav.Link></li>
              <li><Nav.Link as={Link} smooth to="/#achievement" className="footer-link-content" style={{margin: '0'}}>My Achievements</Nav.Link></li>
              <li><Nav.Link as={Link} smooth to="/#projects" className="footer-link-content" style={{margin: '0'}}>My Projects</Nav.Link></li>
              <li><a href="/contact" className='footer-link-content'>Contact Me</a></li>
            </ul>
          </Col>
          <Col xs={12} md={3} style={{marginRight: '0em', paddingBottom: '1em'}}>
            <h4 style={{color:'#fff', fontWeight: '800', paddingBottom: '0.3em'}}>Connect with Me</h4>
            <ul className="list-unstyled">
              <li style={{marginTop: '0.3em'}}><a href='tel:+919742853905' className='footer-link-content'>+91 97428 53905</a></li>
              <div>
                <a href="https://www.instagram.com/tarakrammaram/" target='_blank'><FontAwesomeIcon icon={faInstagram} className='social-link-1'/></a>
                <a href="https://in.linkedin.com/in/tarakram-maram-593b431b2" target='_blank'><FontAwesomeIcon icon={faLinkedin} className='social-link-1'/></a>
                <a href='mailto:tarakram2003@gmail.com'><FontAwesomeIcon icon={faEnvelope} className='social-link-1'/></a>
              </div>
            </ul>
          </Col>
          <Col xs={12} md={3} style={{paddingBottom: '1em'}}>
            <h4 style={{color:'#fff', fontWeight: '800', paddingBottom: '0.3em'}}>Stay In Touch</h4>
            <p style={{color: '#ffffffa9'}}>Get the freshest insights from me.</p>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <div style={{display: 'flex'}}>
                  <Form.Control type="email" onChange={handleEmailChange1} value={email1} placeholder="Your E-Mail ID" className="FormControl" style={{opacity: '0.8', marginRight:'0.2em', backgroundColor: 'transparent', color: '#fff'}}/>
                  <Button onClick={handleSubmit1} variant="primary" className="ml-2" style={{backgroundColor: '#ffffffc1', border: '0px'}}><FontAwesomeIcon style={{color: '#210C6E'}} icon={faPaperPlane} /></Button>
                </div>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
      <hr style={{color: '#fff'}} />
      <div>
        <p className='footer-copy'>&copy; {currentYear} Tarakram Maram | All Rights Reserved.</p>
        <p className='footer-copy-1'>Designed and Developed by <a href="https://tarakrammaram.com/" style={{color: '#fff', textDecoration: 'none', fontWeight: '700'}}>Tarakram Maram</a></p>
        <p className='footer-copy-2'>Powered by <a href="https://visiondigitalindia.com/" target='_blank' style={{color: '#fff', textDecoration: 'none', fontWeight: '700'}}>Vision Digital India™</a></p>
      </div>
      <Offcanvas show={show} onHide={closeSidebar} style={{backgroundColor: '#6ED4A5', color: '#210C6E'}}>  
                <Offcanvas.Header closeButton/>  
                    <div style={{fontFamily: 'Maven Pro', margin: '1em 2em'}}>
                        <h2 style={{fontWeight: '700'}}>Reach Me Out</h2>
                    </div> 
                    <div style={{margin: ' 2.5em 2.5em 0'}}>
                        <MDBInput
                            wrapperClass='mb-4'
                            label={<span style={{ color: '#210C6E', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Name</span>}
                            size='lg'
                            id='form1'
                            type='text'
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #210C6E', 
                                borderRadius: '0',
                                boxShadow: 'none',
                                padding: '10px 0',
                                fontSize: '1em',
                                color: '#210C6E'
                            }}
                            onChange={handleNameChange}
                        />
                        <MDBInput
                            wrapperClass='mb-4'
                            label={<span style={{ color: '#210C6E', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Email</span>}
                            size='lg'
                            id='form2'
                            type='email'
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #210C6E', 
                                borderRadius: 0,
                                boxShadow: 'none',
                                padding: '3px',
                                fontSize: '1em',
                                color: '#210C6E'
                            }}
                            onChange={handleEmailChange}
                        />
                        <MDBInput
                            wrapperClass='mb-4'
                            label={<span style={{ color: '#210C6E', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Message</span>}
                            size='lg'
                            id='form3'
                            type='text'
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #210C6E', 
                                borderRadius: 0,
                                boxShadow: 'none',
                                padding: '5px 0',
                                fontSize: '0.8em',
                                color: '#210C6E'
                            }}
                            onChange={handleMessageChange}
                        />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'end', padding: '0.1em 2.5em'}}>
                        <Button style={{backgroundColor: '#210C6E', border: '3px solid #210C6E', borderRadius: '10px', fontSize: '1em', color: '#fff', fontWeight: '600'}} onClick={handleSubmit}>Submit</Button>
                    </div> 
            </Offcanvas>
            <Modal show={showDoneReport} onHide={handleCloseShowDoneReport}>
              <Modal.Body>
                <Modal.Title style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  <FontAwesomeIcon icon={faCheck} style={{padding: '0.5em', fontSize: '2.3em', color: '#210C6E', fontWeight: '1000'}}/>
                </Modal.Title>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '0.3em 1em 0' }}>
                  <p>Thank you for your submission! I will review your submission and get back to you within 24 hours.</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '1em 0'}}>
                  <Button style={{backgroundColor: '#210C6E', border: 'none'}} onClick={handleCloseShowDoneReport}>
                    Close
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
    </footer>
  );
};

export default Footer;

/*
<div className="social-media-links">
                <a href="https://www.facebook.com/fliptotriptravel/" target='_blank'>
                    <FontAwesomeIcon icon={faFacebook} className='social-link-content'/>
                </a>
                <a href="https://www.youtube.com/@fliptotrip" target='_blank'>
                    <FontAwesomeIcon icon={faYoutube} className='social-link-content'/>
                </a>
                <a href="https://www.instagram.com/flip2trip/" target='_blank'>
                    <FontAwesomeIcon icon={faInstagram} className='social-link-content'/>
                </a>
                <a href="https://www.linkedin.com/company/fliptotrip/" target='_blank'>
                    <FontAwesomeIcon icon={faLinkedin} className='social-link-content'/>
                </a>
                <a href="https://api.whatsapp.com/send/?phone=919740096300&text&type=phone_number&app_absent=0" target="_blank">
                    <FontAwesomeIcon icon={faWhatsapp} className='social-link-content'/>
                </a>
            </div>
*/