import React, {useState, useEffect, useRef} from "react";
import './Home.css'
import Footer from "./footer";
import CustomNavbar from "./Navbar";
import mainImage from './images/About1Image.jpg'
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faFile, faCheck } from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SpaceProject from './images/Space-Project.jpg'
import FoodScan from './images/FoodScan-Project.jpg'
import FoodDine from './images/Food-Dine-In-Project.jpg'
import CarbonImage from './images/Carbon-Project.jpg'
import AuthIndia from './images/AuthIndia-Project.jpg'
import CommuniQeust from './images/ComminiQuest-Project.jpg'
import worldBookImage from './images/World-Book.jpg'
import NasaImage from './images/NS_1.jpg'
import EdutechImg from './images/YS_1.jpg'
import YoungSciImg from './images/YS_1.jpeg'
import BlockChainProjectImage from './images/Blockchain-Project.jpg'
import Resume from './Resume.pdf'
import StickyBox from "react-sticky-box";
import { useInView } from 'react-intersection-observer';
import { Button, Offcanvas, Modal } from 'react-bootstrap';  
import {
    MDBInput
  }
from 'mdb-react-ui-kit';
import {Form} from 'react-bootstrap';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { motion, useScroll, useSpring } from 'framer-motion';
import logoFlipToTrip from './images/flip-to-trip-logo.png'
import logoVisionIndia from './images/VD-Logo.png'
import logoImperial from './images/imperial-logo.png'
import logoMetayu from './images/metayu-logo.png'
import logoGauValley from './images/Gauvally-logo.png'
import logoGEF from './images/GEF-logo.webp'
import logoTanay from './images/Tanay-Logo.png'
import logoOversees from './images/Imperial-Overseas-Logo.webp'
import { database } from './firebase';
import { ref, push } from "firebase/database";
import emailjs from '@emailjs/browser';

const CarouselItem = ({ skillText }) => {
    return (
      <div className="carousel-card">
        <p>{skillText}</p>
      </div>
    );
};

const CarouselItem1 = ({ companyLogo }) => {
    return (
      <div className="carousel-card-1">
        <img src={companyLogo} style={{height: '80px'}} />
      </div>
    );
};
  
  export const cardDetails = {
    0: { title: "Full Stack Development" },
    1: { title: "Data Analyst" },
    2: { title: "Machine Learning" },
    3: { title: "App Development" },
    4: { title: "Deep Learning" },
    5: { title: "DevOps" },
    6: { title: "Software Engineering" },
  };

  export const cardDetails1 = {
    0: { title: "Javascript" },
    1: { title: "Python" },
    2: { title: "C++" },
    3: { title: "React" },
    4: { title: "Docker" },
    5: { title: "Firebase" },
    6: { title: "MySQL" },
    7: { title: "MongoDB" },
    8: { title: "PowerBI" },
    9: { title: "R" },
    10: { title: "TensorFlow" },
  };

  export const cardDetails2 = {
    0: { urlLogo: logoGEF },
    1: {urlLogo: logoOversees},
    2: { urlLogo: logoImperial },
    3: {urlLogo: logoTanay},
    4: { urlLogo: logoFlipToTrip },
    5: { urlLogo: logoVisionIndia },
    6: { urlLogo: logoMetayu },
    7: { urlLogo: logoGauValley },
  };

function Home1 () {

    const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
    const isTab = useMediaQuery({ query: '(max-width: 1100px)' });
    const isTab1 = useMediaQuery({ query: '(min-width: 513px)' });
    const [currentSlide, setCurrentSlide] = useState(0);
    const [show, setShow] = useState(false);  
    const closeSidebar = () => setShow(false);  
    const showSidebar = () => setShow(true); 

    const backgroundMainImage = {
        borderRadius: '50px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        width: '20em',
        display: isTab ? 'none' : 'inline',
        margin: isMobile ? '0 auto' : 'auto',
        transition: 'width 0.1s ease', 
    };

    const { scrollYProgress } = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://static.elfsight.com/platform/platform.js";
        script.async = true;
        script.defer = true;
        script.setAttribute('data-use-service-core', '');
        document.body.appendChild(script);
    
        return () => {
          // Cleanup: remove the script when the component unmounts
          document.body.removeChild(script);
        };
    }, []); 

    const settings = {
        dots: isMobile && isTab1 ? false : (isMobile ? true : true),
        infinite: isMobile ? true : false,
        speed: 300,
        slidesToShow: 1,
        centerMode: false,
        variableWidth: true,
        afterChange: (index) => setCurrentSlide(index),
    };

    const variant = {
        hidden: {
          opacity: 0,
          y: -70
        },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 1.5, 
          },
        },
    };

    const variants = {
        hidden: {
          opacity: 0.8,
          x: 40, 
        },
        visible: {
          opacity: 1,
          x: 0,
          transition: {
            duration: 1, 
          },
        },
    };

    const variants1 = {
        hidden: {
          opacity: 0,
          x: -50, 
        },
        visible: {
          opacity: 1,
          x: 0,
          transition: {
            duration: 1.5, 
          },
        },
    };

    const variants2 = {
        hidden: {
          opacity: 0,
          y: 50, 
        },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 3, 
          },
        },
    };

    const variants3 = {
        hidden: {
          opacity: 0.9,
          y: 60, 
        },
        visible: {
          opacity: 1,
          y: 1,
          transition: {
            duration: 3, 
          },
        },
    };

    const slidesData = [
        {
            imgSrc: SpaceProject,
            tags: ["SPACE SETTLEMENT", "NASA"],
            title: "NASA Space Settlement Project",
            year: "2016-17",
            link: '/home'
        },
        {
            imgSrc: FoodScan,
            tags: ["DEEP LEARNING", "CNN", "MACHINE LEARNING"],
            title: "NutriScan",
            year: "2021",
            link: '/home'
        },
        {
            imgSrc: FoodDine,
            tags: ["APP DEVELOPMENT", "REACT NATIVE", "CLOUD COMPUTING"],
            title: "Smart Dine In App",
            year: "2022",
            link: '/home'
        },
        {
            imgSrc: CarbonImage,
            tags: ["DATA SCIENCE", "MACHINE LEARNING", "PYTHON"],
            title: "Carbon Tracker",
            year: "2023",
            link: '/home'
        },
        {
            imgSrc: AuthIndia,
            tags: ["DEEP LEARNING", "NLP", "CNN"],
            title: "Authentify India",
            year: "2024",
            link: '/home'
        },
        {
            imgSrc: BlockChainProjectImage,
            tags: ["BLOCKCHAIN", "WEB 3.0"],
            title: "Cross-Border Financial Exchange",
            year: "2024-",
            link: '/home'
        },
        {
            imgSrc: CommuniQeust,
            tags: ["APP DEVELOPMENT", "REACT NATIVE"],
            title: "CommuniQeust",
            year: "2024-",
            link: '/home'
        }
    ];

    const fadeFactor = 1.5; 

    const { ref: ref1, entry: entry1 } = useInView({
        threshold: [0, 0.25, 0.5, 0.75, 1]
    });
    const opacity1 = entry1 ? Math.min(entry1.intersectionRatio * fadeFactor, 1) : 0;

    const { ref: ref3, entry: entry3 } = useInView({
        threshold: [0, 0.25, 0.5, 0.75, 1]
    }); 
    const opacity3 = entry1 ? Math.min(entry3.intersectionRatio * fadeFactor, 1) : 0;

    const { ref: ref2, entry: entry2 } = useInView({
        threshold: [0, 0.25, 0.5, 0.75, 1]
    }); 
    const opacity2 = entry2 ? Math.min(entry2.intersectionRatio * fadeFactor, 1) : 0;

    const { ref: ref4, entry: entry4 } = useInView({
        threshold: [0, 0.25, 0.5, 0.75, 1]
    }); 
    const opacity4 = entry2 ? Math.min(entry4.intersectionRatio * fadeFactor, 1) : 0;

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const [showDoneReport, setShowDoneshowDoneReport] = useState(false);

    const handleCloseShowDoneReport = () => setShowDoneshowDoneReport(false);
    const handleShowDoneshowDoneReport = () => setShowDoneshowDoneReport(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name && validateEmail(email) && message) {
            const dataToStore = {
                name: name,
                email: email,
                message: message
            };
          
            const dataRef = ref(database, "message-project"); 
            push(dataRef, dataToStore)
            .then(() => {
              console.log('Data has been stored in Firebase.');

              // Send email using EmailJS with the data from Firebase
              emailjs.send('service_apcd55a', 'template_jejzrnm', {
                to_email: 'tarakram2003@gmail.com',
                subject: 'Website message',
                name: dataToStore.name,
                email: dataToStore.email,
                message: dataToStore.message,
              }, 'Pjh05O3PPHpKASlMj')
                .then((result) => {
                  console.log('Email sent successfully:', result.text);
                })
                .catch((error) => {
                  console.error('Error sending email:', error.text);
                });
            })
            .catch((error) => {
              console.error('Error storing data in Firebase:', error);
            });
            setName('')
            setEmail('')
            setMessage('')
            closeSidebar()
            handleShowDoneshowDoneReport()

        } else {
            alert('Please fill in all the required fields properly');
        }
    };

    const [email1, setEmail1] = useState('')

    const handleEmailChange1 = (e) => {
        setEmail1(e.target.value);
    };

    const handleSubmit1 = (e) => {
        e.preventDefault();
        if (validateEmail(email1)) {
            const dataToStore = {
                name: email1,
                email: email1,
                message: 'Partnership Column'
            };
          
            const dataRef = ref(database, "message-partnership"); 
            push(dataRef, dataToStore)
            .then(() => {
              console.log('Data has been stored in Firebase.');

              // Send email using EmailJS with the data from Firebase
              emailjs.send('service_apcd55a', 'template_jejzrnm', {
                to_email: 'tarakram2003@gmail.com',
                subject: 'Website message',
                name: dataToStore.name,
                email: dataToStore.email,
                message: dataToStore.message,
              }, 'Pjh05O3PPHpKASlMj')
                .then((result) => {
                  console.log('Email sent successfully:', result.text);
                })
                .catch((error) => {
                  console.error('Error sending email:', error.text);
                });
            })
            .catch((error) => {
              console.error('Error storing data in Firebase:', error);
            });
            setEmail1('')
            handleShowDoneshowDoneReport()

        } else {
            alert('Please fill in all the required fields properly');
        }
    };

    return (
        <div>
            <CustomNavbar />
            <motion.div className="progress-bar-007" style={{ scaleX }} />
            <div style={{backgroundColor: '#210C6E', color: '#fff', fontFamily: 'Maven Pro' }}>
                <div className="entrance-main-div">
                    <motion.div className="entrance-main-div-1"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false }}
                        variants={variant}
                    >
                        <div className="entrance-main-div-11">
                            <h1 className="entrance-main-text-font">Coding Leading and Winning. <span style={{textDecoration: 'underline #6ED5A6 6px'}}>My Adventure</span> in Tech Triumphs and Laughs</h1>
                        </div>
                    </motion.div>
                    <motion.div className="entrance-main-div-2"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false }}
                        variants={variants2}
                    >
                        <h4>Dive into digital solutions for tech, laying the framework to accelerate your growth. Let's byte into innovation and debug your path to success!</h4>
                    </motion.div>
                </div>
                <div className="carousel-wrapper" style={{padding: '0 0 3em'}}>
                    <div className="carousel-container">
                        <div className="carousel-track">
                            {Object.keys(cardDetails).map((detailKey) => {
                            return (
                                <CarouselItem
                                skillText={cardDetails[detailKey].title}
                                ></CarouselItem>
                            );
                            })}
                            {Object.keys(cardDetails).map((detailKey) => {
                            return (
                                <CarouselItem
                                skillText={cardDetails[detailKey].title}
                                ></CarouselItem>
                            );
                            })}
                        </div>
                    </div>
                    <div className="carousel-container-1">
                        <div className="carousel-track-1">
                            {Object.keys(cardDetails1).map((detailKey) => {
                            return (
                                <CarouselItem
                                skillText={cardDetails1[detailKey].title}
                                ></CarouselItem>
                            );
                            })}
                            {Object.keys(cardDetails1).map((detailKey) => {
                            return (
                                <CarouselItem
                                skillText={cardDetails1[detailKey].title}
                                ></CarouselItem>
                            );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-achievement-div" id="achievement">
                <h2 className="text-heading">My Achievements</h2>
                {!isTab ? (<>
                <motion.div className="row" style={{ display: 'flex', margin: '6em 4em 10em 2em', gap: '2em' }}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false }}
                    variants={variants1}
                    
                >
                    <StickyBox offsetTop={20} offsetBottom={20} style={{ width: '60%' }}>
                        <p style={{ fontSize: '1.3em', fontWeight: '1000' }}>01</p>
                        <p style={{ fontSize: '1.6em', fontWeight: '1000' }}>Young Scientist - 2016</p>
                        <p>
                            In 2016, I was honored with the Young Scientist award by the Space Kidz organization for my project,
                            which was highly regarded by the jury, including Rakesh Sharma, India’s first astronaut. The recognition
                            was especially significant as Rakesh Sharma himself presented the award to me. This moment was a
                            remarkable highlight in my journey as a young scientist, inspiring me to further explore and innovate in
                            the field of science.
                        </p>
                    </StickyBox>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '60%' }}></div>
                            <div style={{ width: '40%', display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                                <div ref={ref1} style={{ opacity: opacity1, transition: 'opacity 5s ease' }}>
                                    <img src={YoungSciImg} style={{ width: '14em', borderRadius: '30px' }} alt="Tarakram Maram" />
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
                <hr
                    style={{
                        color: '#fff',
                        backgroundColor: '#fff',
                        height: '2px',
                    }}
                />
                <motion.div className="row" style={{display: 'flex', margin: '10em 4em 10em 2em'}}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false }}
                    variants={variants1}
                >
                    <StickyBox offsetTop={20} offsetBottom={20} style={{ width: '60%' }}>
                        <p style={{fontSize: '1.3em', fontWeight: '1000'}}>02</p>
                        <p style={{fontSize: '1.6em', fontWeight: '1000'}}>NASA Space Settlement Contest - 2016/17</p>
                        <p>In 2016/17, I was awarded by NASA and the National Space Society (NSS) at the ISDC conference in St. Louis, USA, for my participation in the NASA Space Settlement Contest. I had the privilege of traveling to the USA to receive this prestigious award in person. This experience was a significant milestone in my academic and scientific endeavors, motivating me to continue pursuing excellence in space science and technology.</p>
                    </StickyBox>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '60%' }}></div>
                            <div style={{ width: '40%', display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                                <div ref={ref3} style={{ opacity: opacity3, transition: 'opacity 5s ease' }}>
                                    <img src={NasaImage} style={{ width: '14em', borderRadius: '30px' }} alt="Tarakram Maram" />
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
                <hr
                    style={{
                        color: '#fff',
                        backgroundColor: '#fff',
                        height: '2px',
                    }}
                />
                <motion.div className="row" style={{display: 'flex', margin: '10em 4em 10em 2em'}}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false }}
                    variants={variants1}
                >
                    <StickyBox offsetTop={20} offsetBottom={20} style={{ width: '60%' }}>
                        <p style={{ fontSize: '1.3em', fontWeight: '1000' }}>03</p>
                        <p style={{ fontSize: '1.6em', fontWeight: '1000' }}>Edutech - 2019</p>
                        <p>
                            In 2019, I participated in Edutech, an event held in Bangalore focused on 
                            future technological software and domains. The event featured discussion sessions 
                            and a competition where participants presented their innovative and visionary projects. 
                            My project was well-received, earning praise from experts, and it was a pivotal experience 
                            that deepened my passion for technology and innovation.
                        </p>
                    </StickyBox>
                    <div style={{display: 'flex'}}>
                        <div style={{ width: '60%' }}></div>
                        <div style={{ width: '40%', display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                            <div ref={ref2} style={{ opacity: opacity2, transition: 'opacity 5s ease', marginTop: '2em' }}>
                                <img src={EdutechImg} style={{ width: '14em', borderRadius: '30px' }} alt="Tarakram Maram" />
                            </div>
                        </div>
                    </div>
                </motion.div>
                <hr
                    style={{
                        color: '#fff',
                        backgroundColor: '#fff',
                        height: '2px',
                    }}
                />
                <motion.div className="row" style={{display: 'flex', margin: '10em 4em 0 2em'}}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false }}
                    variants={variants1}
                >
                    <StickyBox offsetTop={20} offsetBottom={20} style={{ width: '60%',  }}>
                        <p style={{fontSize: '1.3em', fontWeight: '1000'}}>04</p>
                        <p style={{fontSize: '1.6em', fontWeight: '1000'}}>World Book of Record - 2020</p>
                        <p>In 2020, I was honored by the World Book of Records for my leadership and organizational skills. As the lead organizer, I played a pivotal role in orchestrating the largest psychological event in the world, held in Bengaluru in 2020. This achievement showcases my dedication and ability to bring together a massive event, setting a remarkable milestone in the field of psychology.</p>
                    </StickyBox>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '60%' }}></div>
                            <div style={{ width: '40%', display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                                <div ref={ref4} style={{ opacity: opacity4, transition: 'opacity 5s ease' }}>
                                    <img src={worldBookImage} style={{ width: '14em', borderRadius: '30px' }} alt="Tarakram Maram" />
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
                </>) : 
                (<>
                    <motion.div className="row" style={{ display: 'flex', margin: '5em 0', gap: '2em' }}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false }}
                        variants={variants1}
                    >
                        <div style={{ width: '100%' }}>
                            <p style={{ fontSize: '1.3em', fontWeight: '1000' }}>01</p>
                            <p style={{ fontSize: '1.6em', fontWeight: '1000' }}>Young Scientist - 2016</p>
                            <img src={YoungSciImg} style={{ width: '14em', borderRadius: '30px', display: 'flex', margin: '1.8em auto' }} alt="Tarakram Maram" />
                            <p>
                                In 2016, I was honored with the Young Scientist award by the Space Kidz organization for my project,
                                which was highly regarded by the jury, including Rakesh Sharma, India’s first astronaut. The recognition
                                was especially significant as Rakesh Sharma himself presented the award to me. This moment was a
                                remarkable highlight in my journey as a young scientist, inspiring me to further explore and innovate in
                                the field of science.
                            </p>
                        </div>
                    </motion.div>
                    <hr
                        style={{
                            color: '#fff',
                            backgroundColor: '#fff',
                            height: '2px',
                        }}
                    />
                    <motion.div className="row" style={{display: 'flex', margin: '5em 0'}}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false }}
                        variants={variants1}
                    >
                        <div style={{ width: '100%' }}>
                            <p style={{fontSize: '1.3em', fontWeight: '1000'}}>02</p>
                            <p style={{fontSize: '1.6em', fontWeight: '1000'}}>NASA Space Settlement Contest - 2016/17</p>
                            <img src={NasaImage} style={{ width: '14em', borderRadius: '30px', display: 'flex', margin: '1.8em auto' }} alt="Tarakram Maram" />
                            <p>In 2016/17, I was awarded by NASA and the National Space Society (NSS) at the ISDC conference in St. Louis, USA, for my participation in the NASA Space Settlement Contest. I had the privilege of traveling to the USA to receive this prestigious award in person. This experience was a significant milestone in my academic and scientific endeavors, motivating me to continue pursuing excellence in space science and technology.</p>
                        </div>
                    </motion.div>
                    <hr
                        style={{
                            color: '#fff',
                            backgroundColor: '#fff',
                            height: '2px',
                        }}
                    />
                    <motion.div className="row" style={{display: 'flex', margin: '5em 0'}}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false }}
                        variants={variants1}
                    >
                        <div style={{ width: '100%' }}>
                            <p style={{ fontSize: '1.3em', fontWeight: '1000' }}>03</p>
                            <p style={{ fontSize: '1.6em', fontWeight: '1000' }}>Edutech - 2019</p>
                            <img src={EdutechImg} style={{ width: '14em', borderRadius: '30px', display: 'flex', margin: '1.8em auto' }} alt="Tarakram Maram" />
                            <p>
                            In 2019, I participated in Edutech, an event held in Bangalore focused on 
                            future technological software and domains. The event featured discussion sessions 
                            and a competition where participants presented their innovative and visionary projects. 
                            My project was well-received, earning praise from experts, and it was a pivotal experience 
                            that deepened my passion for technology and innovation.
                            </p>
                        </div>
                    </motion.div>
                    <hr
                        style={{
                            color: '#fff',
                            backgroundColor: '#fff',
                            height: '2px',
                        }}
                    />
                    <motion.div className="row" style={{display: 'flex', margin: '5em 0'}}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false }}
                        variants={variants1}
                    >
                        <div style={{ width: '100%' }}>
                            <p style={{fontSize: '1.3em', fontWeight: '1000'}}>04</p>
                            <p style={{fontSize: '1.6em', fontWeight: '1000'}}>World Book of Record - 2020</p>
                            <img src={worldBookImage} style={{ width: '14em', borderRadius: '30px', display: 'flex', margin: '1.8em auto' }} alt="Tarakram Maram" />
                            <p>In 2020, I was honored by the World Book of Records for my leadership and organizational skills. As the lead organizer, I played a pivotal role in orchestrating the largest psychological event in the world, held in Bengaluru in 2020. This achievement showcases my dedication and ability to bring together a massive event, setting a remarkable milestone in the field of psychology.</p>
                        </div>
                    </motion.div>    
                </>)}
            </div>
            <div className="my-project-div" id="projects">
                <h2 className="text-heading">My Projects</h2>
                <div style={{marginTop: '3em'}}>
                    <button onClick={showSidebar} className="collaborate-button">Lets Collaborate</button>
                </div>
                <div style={{margin: '4em 0'}}>
                    <Slider {...settings}>
                        {slidesData.map((slide, index) => (
                            <div key={index} style={{ backgroundColor: currentSlide === index ? '#f0f0f0' : 'white' }}>
                                <img src={slide.imgSrc} className="my-project-image" alt="Tarakram Maram" />
                                <div>
                                    <div className="my-project-tag-div-1">
                                        {slide.tags.map((tag, tagIndex) => (
                                            <div key={tagIndex} style={{padding: '0.3em 0.6em', border: '1px solid #000', borderRadius: '7px'}}>{tag}</div>
                                        ))}
                                    </div>
                                    <div className="my-project-description">
                                        <h2 className="my-project-title">{slide.title}</h2>
                                        <p className="my-project-year">{slide.year}</p>
                                    </div>
                                </div>
                            </div>
                            
                        ))}
                    </Slider>
                </div>
            </div>
            <div className="about-and-others-div" id="about">
                <div className="about-me-div">
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false }}
                        variants={variants3}
                    >
                        <img src={mainImage} alt="Tarakram Maram" style={backgroundMainImage}/>
                    </motion.div>
                    <div>
                        <h2 style={{marginBottom: '0.7em'}} className="text-heading">Who am I?</h2>
                        {isTab ? (<>
                            <motion.div
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                variants={variants3}
                            >
                                <img src={mainImage} className="mobile-about-me-image" alt="" />
                            </motion.div>
                        </>) : <><div></div></>}
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: false }}
                            variants={variants}
                        >
                            <p>Ahoy from the tech shores! Blending Web and App Development with the magic of Data Science, my journey is as wild as a pizza menu—from cheesy freelancing gigs to solid internships. Leading is like directing a symphony, orchestrating victories.</p>
                            <p>When not coding or leading, I'm lost in books or scoring goals on the sports field. Join me for this pun-derful tech adventure, where bugs are critters to squash, and code is brilliance. Together, let's paint the future with vibrant innovation!</p>
                        </motion.div>
                        <motion.div className="phone-and-email-about-me"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: false }}
                            variants={variants}
                        >
                            <div>
                                <a href="tel:+919742853905" className="about-me-contact"><FontAwesomeIcon style={{color: '#562ABD'}} icon={faPhone}/>&nbsp;&nbsp;+91 97428 53905</a>
                            </div>
                            <div>
                                <a href="mailto:tarakram2003@gmail.com" className="about-me-contact"><FontAwesomeIcon style={{color: '#562ABD'}} icon={faEnvelope}/>&nbsp;&nbsp;tarakram2003@gmail.com</a>
                            </div>
                        </motion.div>
                    </div>
                </div>
                <div className="resume-cv-button-div">
                    <div style={{width: '50%'}}><a href={Resume} download="Tarakram Maram"><button className="resume-button">Resume&nbsp;&nbsp;<FontAwesomeIcon icon={faFile}/></button></a></div>
                    <div style={{width: '50%'}}><a href={Resume} download="Tarakram Maram"><button className="cv-button">CV&nbsp;&nbsp;<FontAwesomeIcon icon={faFile}/></button></a></div>
                </div>
                <hr
                    style={{
                        color: 'grey',
                        backgroundColor: 'grey',
                        height: '1.75px',
                        margin: '5em 0'
                    }}
                />
            </div>
            <div>
                <h2 style={{marginBottom: '1.4em', padding: '0 1.5em'}} className="text-heading">My Partners</h2>
                <div class="carousel-wrapper">
                    <div className="carousel-container-2">
                        <div className="carousel-track-2">
                            {Object.keys(cardDetails2).map((detailKey) => {
                            return (
                                <CarouselItem1
                                    companyLogo={cardDetails2[detailKey].urlLogo}
                                ></CarouselItem1>
                            );
                            })}
                            {Object.keys(cardDetails2).map((detailKey) => {
                            return (
                                <CarouselItem1
                                    companyLogo={cardDetails2[detailKey].urlLogo}
                                ></CarouselItem1>
                            );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-and-others-div-1">
                <hr
                    className="about-and-others-div-line-top"
                />
                <motion.div className="partnership-card-div">
                    <div className="partnership-card-007">
                        <div>
                            <h2 className="partnership-chat-card-heading">Partnership chat with me</h2>
                            <p className="partnership-chat-card-content">Let’s find some time to discuss potential collaborations or innovative tech projects!</p>
                            <Form style={{marginTop: '1.5em'}}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <div style={{display: 'flex', gap: '0.4em'}}>
                                        <Form.Control type="email" value={email1} placeholder="Your E-Mail ID" style={{opacity: '0.8'}} onChange={handleEmailChange1}/>
                                        <Button onClick={handleSubmit1} variant="primary" className="ml-2" style={{backgroundColor: '#fff', border: '0px'}}><FontAwesomeIcon style={{color: '#000'}} icon={faPaperPlane} /></Button>
                                    </div>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </motion.div>
            </div>
            <div className="map-div">
                <div className="map-div-1">
                    <hr
                        style={{
                            color: 'grey',
                            backgroundColor: 'grey',
                            height: '1.75px',
                            margin: '0 0 5em'
                        }}
                    />
                    <div>
                    <h2 className="text-heading">Reach Me Here</h2>
                </div>
            </div>
            <div className="map">
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15555.154365882494!2d77.583758!3d12.921305!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae152b6a1c6d2f%3A0x1055f8ab50c80979!2sVision%20Digital%20India!5e0!3m2!1sen!2sin!4v1715584338752!5m2!1sen!2sin" 
                        width="100%" 
                        height="350em" 
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
            <div className="elfsight-app-2e8522ec-eb7a-427d-b25f-8573687f4c70" data-elfsight-app-lazy></div>
            <Footer />
            <Offcanvas show={show} onHide={closeSidebar} style={{backgroundColor: '#562ABD', color: '#fff'}}>  
                <Offcanvas.Header closeButton className="btn-close-white"/>  
                    <div style={{fontFamily: 'Maven Pro', margin: '1em 2em'}}>
                        <h2 style={{fontWeight: '700'}}>Reach Me Out</h2>
                    </div> 
                    <div style={{margin: ' 2.5em 2.5em 0'}}>
                        <MDBInput
                            wrapperClass='mb-4'
                            label={<span style={{ color: '#fff', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Name</span>}
                            size='lg'
                            id='form1'
                            type='text'
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #fff', 
                                borderRadius: '0',
                                boxShadow: 'none',
                                padding: '10px 0',
                                fontSize: '1em',
                                color: '#fff'
                            }}
                            onChange={handleNameChange}
                        />
                        <MDBInput
                            wrapperClass='mb-4'
                            label={<span style={{ color: '#fff', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Email</span>}
                            size='lg'
                            id='form2'
                            type='email'
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #fff', 
                                borderRadius: 0,
                                boxShadow: 'none',
                                padding: '3px',
                                fontSize: '1em',
                                color: '#fff'
                            }}
                            onChange={handleEmailChange}
                        />
                        <MDBInput
                            wrapperClass='mb-4'
                            label={<span style={{ color: '#fff', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Message</span>}
                            size='lg'
                            id='form3'
                            type='text'
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #fff', 
                                borderRadius: 0,
                                boxShadow: 'none',
                                padding: '5px 0',
                                fontSize: '0.8em',
                                color: '#fff'
                            }}
                            onChange={handleMessageChange}
                        />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'end', padding: '0.1em 2.5em'}}>
                        <Button style={{backgroundColor: '#fff', border: '3px solid #fff', borderRadius: '10px', fontSize: '1em', color: '#000', fontWeight: '600'}} onClick={handleSubmit}>Submit</Button>
                    </div> 
            </Offcanvas>
            <Modal show={showDoneReport} onHide={handleCloseShowDoneReport}>
              <Modal.Body>
                <Modal.Title style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  <FontAwesomeIcon icon={faCheck} style={{padding: '0.5em', fontSize: '2.3em', color: '#210C6E', fontWeight: '1000'}}/>
                </Modal.Title>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '0.3em 1em 0' }}>
                  <p>Thank you for your submission! I will review your submission and get back to you within 24 hours.</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '1em 0'}}>
                  <Button style={{backgroundColor: '#210C6E', border: 'none'}} onClick={handleCloseShowDoneReport}>
                    Close
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
        </div>
        
    )
}

export default Home1