import React from 'react';
import './Home.css'
import CustomNavbar from './Navbar';
import i404 from './images/404-error.png'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {

  const history = useNavigate();

  const goHome = () => {
    history('/');
  };

  return (
    <div>    
      <CustomNavbar />
      <div>
        <div className='background-containers'>
        <img src={i404} alt="Tanay Riaz" style={{height: '230px', margin: '0em'}}/>
          <div style={{width: '80%'}}>  
            <h2 style={{fontWeight: '700'}}>Opps! That Links Is Broken.</h2>
            <p>Page does not exist or some other error occured. Go to my Home Page</p>
          </div>
          <Button onClick={goHome} style={{padding: '0.6em', backgroundColor: '#210C6E', color: '#fff', border: '1px solid #210C6E', fontFamily: 'Maven Pro', fontSize: '1.1em', padding: '0.6em 1em', borderRadius: '15px'}}>Go Home</Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;